import React, {useEffect} from 'react';
import * as blob from './mondayOnBoardingGuide.pdf'


const HowToDownloadPage = () => {
    useEffect(()=>{
        const link = document.createElement('a');
        link.href = blob;
        link.download = 'How-To.pdf';
        link.dispatchEvent(new MouseEvent('click'));
    })

    return (
        <div>
        </div>
    );
};

export default HowToDownloadPage;