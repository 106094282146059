import {createSlice, configureStore, combineReducers} from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import {appReducer} from "./AppReducer";



const rootReducer = combineReducers({
    appReducer:appReducer
})


export const store = configureStore({
    reducer:rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(thunkMiddleware)
})