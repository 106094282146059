export const errorsCodes = {
    EMPTY_FIRST_NAME: 1,
    EMPTY_LAST_NAME: 2,
    EMPTY_STREET_ADDRESS: 3,
    EMPTY_CITY: 4,
    EMPTY_STATE: 5,
    EMPTY_ZIP_CODE: 8,
    EMPTY_API_KEY: 9,
    NOT_FOUND_COLUMNS: 10,
    VIEWER_MODE: 11,
    SERVER_ERROR: 510,
    INTERNAL_SERVER_ERROR: 500,
}


export const errorMessGenerator = (code, errorMess) => {
    const {
        NOT_FOUND_COLUMNS,
        EMPTY_FIRST_NAME,
        EMPTY_LAST_NAME,
        EMPTY_STREET_ADDRESS,
        EMPTY_CITY,
        EMPTY_STATE,
        EMPTY_ZIP_CODE,
        EMPTY_API_KEY,
        SERVER_ERROR,
        INTERNAL_SERVER_ERROR,
        VIEWER_MODE
    } = errorsCodes
    switch (code) {
        case EMPTY_FIRST_NAME:
            return 'Error 1: First Name is Required. Please enter a First Name.'
        case EMPTY_LAST_NAME:
            return 'Error 2: Last Name is Required. Please enter a Last Name.'
        case EMPTY_STREET_ADDRESS:
            return 'Error 3: Street Address is Required. Please enter a Street Address.'
        case EMPTY_CITY:
            return 'Error 4: City is Required. Please enter a City.'
        case EMPTY_STATE:
            return 'Error 5: State is Required. Please enter a State.'
        case EMPTY_ZIP_CODE:
            return 'Error 8: Zip is Required. Please enter a Zip.'
        case EMPTY_API_KEY:
            return 'Error 9: DS API Key Not Found. Please check DS API Key settings and try again.'
        case NOT_FOUND_COLUMNS:
            return 'Error 10: DS Columns not found. Please check that DS Columns are added to your board.';
        case VIEWER_MODE:
            return 'Error 11: As a viewer, you cannot perform updates.';
        case SERVER_ERROR:
            return `Error 510: DonorSearch Error: ${errorMess}`
        case INTERNAL_SERVER_ERROR:
            return 'Error 500: Internal Server Error. Please contact DonorSearch support.'
    }
}