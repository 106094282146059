import {monday} from "../pages/App";

export const createUnExistColumnOnBoard =async (title,type,boardId,description)=> {
    try {
        await monday.api(`mutation {
        create_column (board_id: ${boardId}, title: ${JSON.stringify(title)}, description: ${JSON.stringify(description)} column_type:${type}) {
            id
        }
    }`)
    }catch(e){

    }
}