import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./pages/App";
import "monday-ui-react-core/dist/main.css"
import {Provider} from "react-redux";
import {store} from "./redux/store";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HowToDownloadPage from "./pages/howToDownloadPage/howToDownloadPage";

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route path="/How-To.pdf" element={<HowToDownloadPage />} />
                <Route path="*" element={<App />} />
            </Routes>
        </BrowserRouter>
    </Provider>, document.getElementById("root"));

