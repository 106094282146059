import React, {useEffect, useState} from "react";
import "./App.css";
import mondaySdk from "monday-sdk-js";
import Button from "monday-ui-react-core/dist/Button";
import {useDispatch, useSelector} from "react-redux";
import {
    changeDateColumnOnMondayBoard,
    changeLastUpdateStatusColumnOnMondayBoard,
    getDataFromMondayGraphAPI,
    getUserDataFromDSApi,
    neededColumnNames,
    setErrorText,
    setPageStatus
} from "../redux/AppReducer";
import {getEnv, getVersion} from "../utils/buildinfo";
import {ErrorMsg} from "../ui/ErrorMsg";
import logo from '../assets/donorsearch_logo.png'
import {errorMessGenerator, errorsCodes} from "../utils/errorsHandler";
import * as blob from '../pages/howToDownloadPage/mondayOnBoardingGuide.pdf'
import jwt_decode from "jwt-decode";

export const monday = mondaySdk();

const App = () => {

    const successIcon = () => {
        return <svg viewBox="0 0 20 20" fill="currentColor" width={"20"} height={"20"}>
            <path
                d="M8.53033 14.2478L8 13.7175L7.46967 14.2478C7.76256 14.5407 8.23744 14.5407 8.53033 14.2478ZM8 12.6569L4.53033 9.18718C4.23744 8.89429 3.76256 8.89429 3.46967 9.18718C3.17678 9.48008 3.17678 9.95495 3.46967 10.2478L7.46967 14.2478L8 13.7175L8.53033 14.2478L16.2478 6.53033C16.5407 6.23743 16.5407 5.76256 16.2478 5.46967C15.955 5.17677 15.4801 5.17677 15.1872 5.46967L8 12.6569Z"
                fill="currentColor" fillRule="evenodd" clipRule="evenodd"/>
        </svg>
    }

    const [settings, setSettings] = useState({})
    const [context, setContext] = useState({})
    const [FirstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [streetAddress, setStreetAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [APIKey, setAPIKey] = useState('')
    const [isViewOnly, setIsViewOnly] = useState(true)
    const dispatch = useDispatch()
    const pageStatus = useSelector((state) => state.appReducer.pageStatus)
    const errorText = useSelector((state) => state.appReducer.errorText)
    const isSuccess = useSelector((state) => state.appReducer.isSuccess)
    const boardData = useSelector((state) => state.appReducer.BoardData)
    const lastUpdate = useSelector((state) => state.appReducer.lastUpdate)
    const isRecursionFinished = useSelector((state) => state.appReducer.isRecursionFinished)

    useEffect(() => {
        monday.get("sessionToken").then(res=> {
            setIsViewOnly(jwt_decode(res.data).dat.is_view_only)
        })
        monday.listen("settings", (res) => {
            setSettings(res.data);
        });

        monday.listen("context", res => {
            setContext(res.data);
            dispatch(getDataFromMondayGraphAPI({itemId: res.data.itemId}))
        })


    }, [])

    useEffect(()=>{
        dispatch(setPageStatus('loading'))
        setTimeout(()=>{
            dispatch(setPageStatus('idle'))
        },1500)
    },[])

//itemId: '2620150811',boardId:'2620149626 => for develop mode

    const setBoardValues = (property, callbackToSet) => {
        if (property?.trim().length > 0) {
            callbackToSet(property)
        } else {
            callbackToSet('')
        }
    }

    useEffect(() => {
        const columnValues = boardData[0]?.column_values

        const firstNameField = settings.firstName ? Object.keys(settings.firstName)[0] : undefined
        const lastNameField = settings.lastName ? Object.keys(settings.lastName)[0] : undefined
        const middleNameField = settings.middleName ? Object.keys(settings.middleName)[0] : undefined
        const homeCityField = settings.homeCity ? Object.keys(settings.homeCity)[0] : undefined
        const homeStateField = settings.homeState ? Object.keys(settings.homeState)[0] : undefined
        const homeStreetAddressField = settings.homeStreetAddress ? Object.keys(settings.homeStreetAddress)[0] : undefined
        const homeZipField = settings.homeZip ? Object.keys(settings.homeZip)[0] : undefined

        const firstName = firstNameField ? columnValues?.find(t => t.id === firstNameField)?.text : ''
        const lastName = lastNameField ? columnValues?.find(t => t.id === lastNameField)?.text : ''
        const middleName = middleNameField ? columnValues?.find(t => t.id === middleNameField)?.text : ''
        const StreetAddress = homeStreetAddressField ? columnValues?.find(t => t.id === homeStreetAddressField)?.text : ''
        const City = homeCityField ? columnValues?.find(t => t.id === homeCityField)?.text : ''
        const State = homeStateField ? columnValues?.find(t => t.id === homeStateField)?.text : ''
        const ZipCode = homeZipField ? columnValues?.find(t => t.id === homeZipField)?.text : ''
        const ApiKey = settings['DS Api Key'] ? settings['DS Api Key'] : ''

        setBoardValues(firstName, setFirstName)
        setBoardValues(lastName, setLastName)
        setBoardValues(middleName, setMiddleName)
        setBoardValues(StreetAddress, setStreetAddress)
        setBoardValues(City, setCity)
        setBoardValues(State, setState)
        setBoardValues(ZipCode, setZipCode)
        setBoardValues(ApiKey, setAPIKey)

    }, [settings, boardData])



    const onUpdateDataHandler = () => {
            dispatch(setErrorText(''));
            if(isViewOnly){
                dispatch(setErrorText(errorMessGenerator(errorsCodes.VIEWER_MODE)))
            }else {
                if (APIKey.length === 0 || FirstName.length === 0 || lastName.length === 0 || streetAddress.length === 0 || city.length === 0 || state.length === 0 || zipCode.length === 0) {
                    if (APIKey.length === 0) {
                        dispatch(setErrorText(errorMessGenerator(errorsCodes.EMPTY_API_KEY)))
                        dispatch(changeLastUpdateStatusColumnOnMondayBoard({
                            itemId: context.itemId,
                            boardId: context.boardId,
                            status: errorMessGenerator(errorsCodes.EMPTY_API_KEY)
                        }))
                        dispatch(changeDateColumnOnMondayBoard({
                            itemId: context.itemId,
                            boardId: context.boardId,
                        }))
                        return
                    }
                    if (FirstName.length === 0) {
                        dispatch(setErrorText(errorMessGenerator(errorsCodes.EMPTY_FIRST_NAME)))
                        dispatch(changeLastUpdateStatusColumnOnMondayBoard({
                            itemId: context.itemId,
                            boardId: context.boardId,
                            status: errorMessGenerator(errorsCodes.EMPTY_FIRST_NAME)
                        }))
                        dispatch(changeDateColumnOnMondayBoard({
                            itemId: context.itemId,
                            boardId: context.boardId,
                        }))
                        return
                    }
                    if (lastName.length === 0) {
                        dispatch(setErrorText(errorMessGenerator(errorsCodes.EMPTY_LAST_NAME)))
                        dispatch(changeLastUpdateStatusColumnOnMondayBoard({
                            itemId: context.itemId,
                            boardId: context.boardId,
                            status: errorMessGenerator(errorsCodes.EMPTY_LAST_NAME)
                        }))
                        dispatch(changeDateColumnOnMondayBoard({
                            itemId: context.itemId,
                            boardId: context.boardId,
                        }))
                        return
                    }
                    if (streetAddress.length === 0) {
                        dispatch(setErrorText(errorMessGenerator(errorsCodes.EMPTY_STREET_ADDRESS)))
                        dispatch(changeLastUpdateStatusColumnOnMondayBoard({
                            itemId: context.itemId,
                            boardId: context.boardId,
                            status: errorMessGenerator(errorsCodes.EMPTY_STREET_ADDRESS)
                        }))
                        dispatch(changeDateColumnOnMondayBoard({
                            itemId: context.itemId,
                            boardId: context.boardId,
                        }))
                        return
                    }
                    if (city.length === 0) {
                        dispatch(setErrorText(errorMessGenerator(errorsCodes.EMPTY_CITY)))
                        dispatch(changeLastUpdateStatusColumnOnMondayBoard({
                            itemId: context.itemId,
                            boardId: context.boardId,
                            status: errorMessGenerator(errorsCodes.EMPTY_CITY)
                        }))
                        dispatch(changeDateColumnOnMondayBoard({
                            itemId: context.itemId,
                            boardId: context.boardId,
                        }))
                        return
                    }
                    if (state.length === 0) {
                        dispatch(setErrorText(errorMessGenerator(errorsCodes.EMPTY_STATE)))
                        dispatch(changeLastUpdateStatusColumnOnMondayBoard({
                            itemId: context.itemId,
                            boardId: context.boardId,
                            status: errorMessGenerator(errorsCodes.EMPTY_STATE)
                        }))
                        dispatch(changeDateColumnOnMondayBoard({
                            itemId: context.itemId,
                            boardId: context.boardId,
                        }))
                        return
                    }
                    if (zipCode.length === 0) {
                        dispatch(setErrorText(errorMessGenerator(errorsCodes.EMPTY_ZIP_CODE)))
                        dispatch(changeLastUpdateStatusColumnOnMondayBoard({
                            itemId: context.itemId,
                            boardId: context.boardId,
                            status: errorMessGenerator(errorsCodes.EMPTY_ZIP_CODE)
                        }))
                        dispatch(changeDateColumnOnMondayBoard({
                            itemId: context.itemId,
                            boardId: context.boardId,
                        }))
                        return
                    }
                } else {
                    dispatch(getUserDataFromDSApi({
                        itemId: context.itemId,
                        boardId: context.boardId,
                        firstName: FirstName,
                        lastName: lastName,
                        middleName: middleName,
                        streetAddress: streetAddress,
                        city: city,
                        state: state,
                        zipCode: zipCode,
                        APIKey: APIKey
                    }))
                }
            }}



    return (
        <div className="App" style={{background: settings.background}}>
            <div className={'app-header'}>
                <img className={'logo-img'} src={logo} alt="ds_logo"/>
                <div className={'triangle-content-box'}>
                    <div className={'triangle-1-2'}></div>
                    <div className={'triangle-3-2'}></div>
                    <div className={'triangle-2-1'}></div>
                    <div className={'triangle-1-1'}></div>
                    <div className={'triangle-3-1'}></div>
                </div>
            </div>
            <ErrorMsg isError={errorText} isSuccess={isRecursionFinished}/>
            <div className={'title-box'}>
                <div><h1 className={'app-title'}
                         style={{color: context.theme === 'light' ? '#000000' : '#ffffff'}}>DonorSearch Constituent
                    Update</h1>
                </div>
                <div className={'info-guide-text'} style={{color: context.theme === 'light' ? '#000000' : '#ffffff'}}>
                    <p>Usage:</p>
                    <p>Open the settings and map the DS API key and columns.</p>
                    <p>For details, follow the <a href={blob} target="_blank" rel="noreferrer" className={'link-to-how-to-pdf'}>How-To guide</a></p>
                </div>
                <div className={'update-data-btn'}>
                    <div className={'btn-block'}>
                        <Button className={'btn-class'} success={isSuccess} successIcon={successIcon}
                                successText="Success"
                                loading={pageStatus === 'loading' && !isSuccess} onClick={onUpdateDataHandler}
                                size={Button.sizes.LARGE}><label className={'btn-update-content-text'}>Update DS
                            data</label></Button>
                        <div className={'last-update-label'}
                             style={{color: context.theme === 'light' ? '#000000' : '#ffffff'}}>Last
                            update: {lastUpdate ? lastUpdate : 'No updates found'}</div>
                    </div>
                </div>
            </div>
                <div className={'footer-block'}>
                    <div className={'triangle-content-box'}>
                        <div className={'triangle-1-4'}></div>
                        <div className={'triangle-3-4'}></div>
                        <div className={'triangle-3-3'}></div>
                        <div className={'triangle-1-3'}></div>
                    </div>
                    <h4 className={'version-info'}
                        style={{color: context.theme === 'light' ? '#000000' : '#ffffff'}}>v({getVersion()})({getEnv()})</h4>
                </div>
            </div>
            )
            }

            export default App;
