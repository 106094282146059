import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {donorSearchAPI} from "../services/donorSearchAPI";
import moment from "moment";
import {errorMessGenerator, errorsCodes} from "../utils/errorsHandler";
import {monday} from "../pages/App";
import {createUnExistColumnOnBoard} from "../utils/common";


const neededColumnNames = [
    {
        title: 'DS Profile',
        type: 'link',
        description: 'Link to the DonorSearch profile.'
    },
    {
        title: 'DS-Rating',
        type: 'status',
        description: 'Propensity to give score, DS1-1 is top opportunity, DS1-5 is lowest qualified opportunity, DS3 doesn’t have high quality matched data.'
    },
    {
        title: 'Capacity Based on Wealth',
        type: 'status',
        description: 'Displayed as a range called Target Ask by Wealth in the detailed profile. Estimated 10% of the individuals net worth, presented as a range, could be donated over a 5-year pledge period.'
    },
    {
        title: 'Lifetime Charitable Giving',
        type: 'numbers',
        description: 'Total dollars the constituent likely donated to all charitable organizations.'
    },
    {
        title: 'Number of Lifetime Charitable Gifts',
        type: 'numbers',
        description: 'Total number of gifts the constituent likely donated to all charitable organizations.'
    },
    {
        title: 'PGID-Rating',
        type: 'text',
        description: 'Planned gift likelihood score, 1 is most likely to respond positively to planned gift marketing, 7 is least likely to respond.'
    },
    {
        title: 'RFM Total',
        type: 'numbers',
        description: 'Relative affinity ranking compared to others submit. 0 to 300 score the higher the better.'
    },
    {
        title: 'Lowest Giving Range',
        type: 'numbers',
        description: 'Lower range of the single largest gift the individual gave elsewhere.'
    },
    {
        title: 'Last Update Status',
        type: 'text',
        description: 'Status of last update.'
    },
    {title: 'Last Update Date', type: 'date', description: 'Date of last update.'}
]


export const getUserDataFromDSApi = createAsyncThunk(
    'AppReducer/getUserDataFromDSApi',
    async ({itemId, boardId, firstName, lastName, middleName, streetAddress, city, state, zipCode, APIKey,isRecursive}, {
        dispatch,
        getState
    }) => {
        const columnIdsNeeded = getState().appReducer.columnsIds
        if (!columnIdsNeeded.find(t => Object.keys(t)[0] === 'DS Profile')
            || !columnIdsNeeded.filter(t => Object.keys(t)[0] ==='DS Profile').some(t=>t.type === 'link')
            || !columnIdsNeeded.find(t => Object.keys(t)[0] === 'DS-Rating')
            || !columnIdsNeeded.filter(t => Object.keys(t)[0] ==='DS-Rating').some(t=>t.type === 'color')
            || !columnIdsNeeded.find(t => Object.keys(t)[0] === 'Capacity Based on Wealth')
            || !columnIdsNeeded.filter(t => Object.keys(t)[0] ==='Capacity Based on Wealth').some(t=>t.type === 'color')
            || !columnIdsNeeded.find(t => Object.keys(t)[0] === 'PGID-Rating')
            || !columnIdsNeeded.filter(t => Object.keys(t)[0] === 'PGID-Rating').some(t=>t.type === 'text')
            || !columnIdsNeeded.find(t => Object.keys(t)[0] === 'Lowest Giving Range')
            || !columnIdsNeeded.filter(t => Object.keys(t)[0] === 'Lowest Giving Range').some(t=>t.type === 'numeric')
            || !columnIdsNeeded.find(t => Object.keys(t)[0] === 'RFM Total')
            || !columnIdsNeeded.filter(t => Object.keys(t)[0] === 'RFM Total').some(t=>t.type === 'numeric')
            || !columnIdsNeeded.find(t => Object.keys(t)[0] === 'Lifetime Charitable Giving')
            || !columnIdsNeeded.filter(t => Object.keys(t)[0] === 'Lifetime Charitable Giving').some(t=>t.type === 'numeric')
            || !columnIdsNeeded.find(t => Object.keys(t)[0] === 'Number of Lifetime Charitable Gifts')
            || !columnIdsNeeded.filter(t => Object.keys(t)[0] === 'Number of Lifetime Charitable Gifts').some(t=>t.type === 'numeric')
            || !columnIdsNeeded.find(t => Object.keys(t)[0] === 'Last Update Date')
            || !columnIdsNeeded.filter(t => Object.keys(t)[0] === 'Last Update Date').some(t=>t.type === 'date')
            || !columnIdsNeeded.find(t => Object.keys(t)[0] === 'Last Update Status')
            || !columnIdsNeeded.filter(t => Object.keys(t)[0] === 'Last Update Status').some(t=>t.type === 'text')){
            // dispatch(setErrorText(errorMessGenerator(errorsCodes.NOT_FOUND_COLUMNS)))
            let neededToCreateColumns = [];
            if (!columnIdsNeeded.find(t => Object.keys(t)[0] === 'DS Profile')
                || !columnIdsNeeded.filter(t => Object.keys(t)[0] ==='DS Profile').some(t=>t.type === 'link')) {
                neededToCreateColumns.push(neededColumnNames[0])
            }
            if (!columnIdsNeeded.find(t => Object.keys(t)[0] === 'DS-Rating')
                || !columnIdsNeeded.filter(t => Object.keys(t)[0] ==='DS-Rating').some(t=>t.type === 'color')) {
                neededToCreateColumns.push(neededColumnNames[1])
            }
            if (!columnIdsNeeded.find(t => Object.keys(t)[0] === 'Lifetime Charitable Giving')
                || !columnIdsNeeded.filter(t => Object.keys(t)[0] === 'Lifetime Charitable Giving').some(t=>t.type === 'numeric')) {
                neededToCreateColumns.push(neededColumnNames[3])
            }
            if (!columnIdsNeeded.find(t => Object.keys(t)[0] === 'Number of Lifetime Charitable Gifts')
                || !columnIdsNeeded.filter(t => Object.keys(t)[0] === 'Number of Lifetime Charitable Gifts').some(t=>t.type === 'numeric')) {
                neededToCreateColumns.push(neededColumnNames[4])
            }
            if (!columnIdsNeeded.find(t => Object.keys(t)[0] === 'PGID-Rating')
                || !columnIdsNeeded.filter(t => Object.keys(t)[0] === 'PGID-Rating').some(t=>t.type === 'text')) {
                neededToCreateColumns.push(neededColumnNames[5])
            }
            if (!columnIdsNeeded.find(t => Object.keys(t)[0] === 'RFM Total')
                || !columnIdsNeeded.filter(t => Object.keys(t)[0] === 'RFM Total').some(t=>t.type === 'numeric')) {
                neededToCreateColumns.push(neededColumnNames[6])
            }
            if (!columnIdsNeeded.find(t => Object.keys(t)[0] === 'Lowest Giving Range')
                || !columnIdsNeeded.filter(t => Object.keys(t)[0] === 'Lowest Giving Range').some(t=>t.type === 'numeric')) {
                neededToCreateColumns.push(neededColumnNames[7])
            }
            if (!columnIdsNeeded.find(t => Object.keys(t)[0] === 'Capacity Based on Wealth')
                || !columnIdsNeeded.filter(t => Object.keys(t)[0] ==='Capacity Based on Wealth').some(t=>t.type === 'color')) {
                neededToCreateColumns.push(neededColumnNames[2])
            }
            if (!columnIdsNeeded.find(t => Object.keys(t)[0] === 'Last Update Status')
                || !columnIdsNeeded.filter(t => Object.keys(t)[0] === 'Last Update Status').some(t=>t.type === 'text')) {
                neededToCreateColumns.push(neededColumnNames[8])
            }
            if (!columnIdsNeeded.find(t => Object.keys(t)[0] === 'Last Update Date')
                || !columnIdsNeeded.filter(t => Object.keys(t)[0] === 'Last Update Date').some(t=>t.type === 'date') ) {
                neededToCreateColumns.push(neededColumnNames[9])
            }
             if(neededToCreateColumns.length>0){
                 dispatch(setPageStatus('loading'))
                 donorSearchAPI.getDonorInfo({
                     apiKey: APIKey,
                     firstName: firstName,
                     lastName: lastName,
                     middleName: middleName,
                     streetAddress: streetAddress,
                     city: city,
                     state: state,
                     zipCode: zipCode,
                     clientId: itemId
                 }).then((res)=>{
                     neededToCreateColumns.map(({type,title,description},i)=>{
                         setTimeout(()=>{
                             createUnExistColumnOnBoard(title,type,boardId,description).then((res)=>{
                                 if(i+1===neededToCreateColumns.length){
                                     dispatch(getDataFromMondayGraphAPI({itemId, boardId, firstName, lastName, middleName, streetAddress, city, state, zipCode, APIKey,isRecursive:true}))
                                 }
                             })
                         },1000*i)

                     })
                 }).catch((error)=>{
                     dispatch(setErrorText(errorMessGenerator(errorsCodes.SERVER_ERROR, 'Unauthorized - invalid api key.')))
                     dispatch(changeLastUpdateStatusColumnOnMondayBoard({
                         itemId,
                         boardId,
                         status: errorMessGenerator(errorsCodes.SERVER_ERROR, 'Unauthorized - invalid api key.')
                     }))
                 })

             }
            return
        } else {
            dispatch(setPageStatus('loading'))
            try {
                const res = await donorSearchAPI.getDonorInfo({
                    apiKey: APIKey,
                    firstName: firstName,
                    lastName: lastName,
                    middleName: middleName,
                    streetAddress: streetAddress,
                    city: city,
                    state: state,
                    zipCode: zipCode,
                    clientId: itemId
                })
                if (res?.data === "Missing Required Data") {
                    dispatch(setErrorText(errorMessGenerator(errorsCodes.SERVER_ERROR, 'Missing Required Data')))
                    dispatch(changeLastUpdateStatusColumnOnMondayBoard({
                        itemId,
                        boardId,
                        status: errorMessGenerator(errorsCodes.SERVER_ERROR, 'Missing Required Data')
                    }))

                }


                const fields = {
                    DS_Rating: res.data.individual.DS_Rating,
                    CapacityRangeBasedOnWealth: res.data.individual.CapacityRangeBasedOnWealth,
                    LikelyMatchesCount: res.data.individual.LikelyMatchesCount,
                    LikelyMatchesTotal: res.data.individual.LikelyMatchesTotal,
                    PGID_Rating: res.data.individual.PGID_Rating,
                    RFMTotal: res.data.individual.RFMTotal,
                    LargestGiftLow: res.data.individual.LargestGiftLow,
                }
                if(!isRecursive) {
                    dispatch(changeCustomFieldOnMondayBoard({
                        itemId,
                        boardId,
                        columnsValuesString: `{${JSON.stringify(columnIdsNeeded.find(t => Object.keys(t)[0] === 'DS-Rating' && t.type ==='color')['DS-Rating'])} : {\"label\": ${JSON.stringify(res.data.individual.DS_Rating)}}}`
                    }))
                }
                dispatch(changeCustomFieldOnMondayBoard({
                    itemId,
                    boardId,
                    columnsValuesString: `{${JSON.stringify(columnIdsNeeded.find(t => Object.keys(t)[0] === 'Capacity Based on Wealth' && t.type ==='color')['Capacity Based on Wealth'])} : {\"label\": ${JSON.stringify(res.data.individual.CapacityRangeBasedOnWealth)}}}`
                }))

                dispatch(changeCustomFieldOnMondayBoard({
                    itemId,
                    boardId,
                    columnsValuesString: `{${JSON.stringify(columnIdsNeeded.find(t => Object.keys(t)[0] === 'DS Profile' && t.type ==='link')['DS Profile'])} : {\"url\" : ${JSON.stringify(res.data.individual.ProfileLink)} , \"text\": ${JSON.stringify(res.data.individual.ProfileLink)}}}`
                }))
                dispatch(changeCustomFieldOnMondayBoard({
                    itemId,
                    boardId,
                    columnsValuesString: `{${JSON.stringify(columnIdsNeeded.find(t => Object.keys(t)[0] === 'PGID-Rating' && t.type ==='text')['PGID-Rating'])} : \"${res.data.individual.PGID_Rating}\"}`
                }))
                dispatch(changeCustomFieldOnMondayBoard({
                    itemId,
                    boardId,
                    columnsValuesString: `{${JSON.stringify(columnIdsNeeded.find(t => Object.keys(t)[0] === 'Lowest Giving Range' && t.type ==='numeric')['Lowest Giving Range'])} : \"${res.data.individual.LargestGiftLow}\"}`
                }))
                dispatch(changeCustomFieldOnMondayBoard({
                    itemId,
                    boardId,
                    columnsValuesString: `{${JSON.stringify(columnIdsNeeded.find(t => Object.keys(t)[0] === 'RFM Total' && t.type ==='numeric')['RFM Total'])} : \"${res.data.individual.RFMTotal}\"}`
                }))
                dispatch(changeCustomFieldOnMondayBoard({
                    itemId,
                    boardId,
                    columnsValuesString: `{${JSON.stringify(columnIdsNeeded.find(t => Object.keys(t)[0] === 'Lifetime Charitable Giving' && t.type ==='numeric')['Lifetime Charitable Giving'])} : \"${res.data.individual.LikelyMatchesTotal}\"}`
                }))
                dispatch(changeCustomFieldOnMondayBoard({
                    itemId,
                    boardId,
                    columnsValuesString: `{${JSON.stringify(columnIdsNeeded.find(t => Object.keys(t)[0] === 'Capacity Based on Wealth' && t.type ==='color')['Capacity Based on Wealth'])} : {\"label\": ${JSON.stringify(res.data.individual.CapacityRangeBasedOnWealth)}}}`
                }))
                dispatch(changeCustomFieldOnMondayBoard({
                    itemId,
                    boardId,
                    columnsValuesString: `{${JSON.stringify(columnIdsNeeded.find(t => Object.keys(t)[0] === 'Number of Lifetime Charitable Gifts' && t.type ==='numeric')['Number of Lifetime Charitable Gifts'])} : \"${res.data.individual.LikelyMatchesCount}\"}`
                }))
                if(isRecursive){
                    setTimeout(()=>{
                        dispatch(changeCustomFieldOnMondayBoard({
                            itemId,
                            boardId,
                            columnsValuesString: `{${JSON.stringify(columnIdsNeeded.find(t => Object.keys(t)[0] === 'DS-Rating' && t.type ==='color')['DS-Rating'])} : {\"label\": ${JSON.stringify(res.data.individual.DS_Rating)}}}`
                        }))
                    },1500)
                }

                dispatch(changeLastUpdateStatusColumnOnMondayBoard({itemId, boardId, status: 'Success'}))
                dispatch(setIsSuccess(true))
                if(isRecursive){
                    dispatch(setIsRecursionFinished(true))
                }
            } catch (e) {
                switch (e.message) {
                    case 'Request failed with status code 401' : {
                        dispatch(setErrorText(errorMessGenerator(errorsCodes.SERVER_ERROR, 'Unauthorized - invalid api key.')))
                        dispatch(changeLastUpdateStatusColumnOnMondayBoard({
                            itemId,
                            boardId,
                            status: errorMessGenerator(errorsCodes.SERVER_ERROR, 'Unauthorized - invalid api key.')
                        }))
                        break;
                    }
                    default:
                        dispatch(setErrorText(errorMessGenerator(errorsCodes.INTERNAL_SERVER_ERROR)))
                        dispatch(changeLastUpdateStatusColumnOnMondayBoard({
                            itemId,
                            boardId,
                            status: errorMessGenerator(errorsCodes.INTERNAL_SERVER_ERROR)
                        }))
                        break;
                }
                dispatch(setPageStatus('idle'))
            } finally {
                dispatch(changeDateColumnOnMondayBoard({itemId, boardId}))
            }
        }
        dispatch(setPageStatus('idle'))
    }
);


export const changeDateColumnOnMondayBoard = createAsyncThunk(
    'AppReducer/changeDateColumnOnMondayBoard',
    async ({itemId, boardId,}, {dispatch, getState}) => {
        dispatch(setPageStatus('loading'))
        const columnIdsNeeded = getState().appReducer.columnsIds
             if(columnIdsNeeded.length>=10) {
                 try {
                     const NewCorrectDate = moment().format('YYYY-MM-DD')
                     const variables = JSON.stringify({
                         myBoardId: Number(boardId),
                         myItemId: Number(itemId),
                         myColumnValues: `{${JSON.stringify(columnIdsNeeded.find(t => Object.keys(t)[0] === 'Last Update Date' && t.type ==='date' )['Last Update Date'])} : {\"date\" : ${JSON.stringify(NewCorrectDate)} }}`
                     })
                     await monday.api(`mutation ($myBoardId:Int!, $myItemId:Int!, $myColumnValues:JSON!) { change_multiple_column_values(item_id:$myItemId, board_id:$myBoardId, column_values: $myColumnValues) { id } }`, {variables})
                     dispatch(setLastUpdate(NewCorrectDate))
                     dispatch(setPageStatus('idle'))
                 } catch (e) {
                     dispatch(setErrorText('Error 500: Internal Server Error. Please contact DonorSearch support.'))
                     dispatch(changeLastUpdateStatusColumnOnMondayBoard({
                         itemId,
                         boardId,
                         status: 'Error 500: Internal Server Error. Please contact DonorSearch support.'
                     }))
                     dispatch(setPageStatus('idle'))
                 }
             }else{
                 dispatch(setPageStatus('idle'))
             }
    })

export const changeLastUpdateStatusColumnOnMondayBoard = createAsyncThunk(
    'AppReducer/changeLastUpdateStatusColumnOnMondayBoard',
    async ({itemId, boardId, status}, {dispatch, getState}) => {
        dispatch(setPageStatus('loading'))
        const columnIdsNeeded = getState().appReducer.columnsIds
        if(columnIdsNeeded.length>=10) {
            try {
                const variables = JSON.stringify({
                    myBoardId: Number(boardId),
                    myItemId: Number(itemId),
                    myColumnValues: `{${JSON.stringify(columnIdsNeeded.find(t => Object.keys(t)[0] === 'Last Update Status'&& t.type ==='text')['Last Update Status'])} : ${JSON.stringify(status)}}`
                })
                await monday.api(`mutation ($myBoardId:Int!, $myItemId:Int!, $myColumnValues:JSON!) { change_multiple_column_values(item_id:$myItemId, board_id:$myBoardId, column_values: $myColumnValues) { id }}`, {variables})
                dispatch(setPageStatus('idle'))
            } catch (e) {
                dispatch(setErrorText('Error 500: Internal Server Error. Please contact DonorSearch support.'))
                dispatch(changeLastUpdateStatusColumnOnMondayBoard({
                    itemId,
                    boardId,
                    status: 'Error 500: Internal Server Error. Please contact DonorSearch support.'
                }))
                dispatch(setPageStatus('idle'))
            }
        }else{
            dispatch(setPageStatus('idle'))
        }
    })

export const changeCustomFieldOnMondayBoard = createAsyncThunk(
    'AppReducer/changeCustomFieldOnMondayBoard',
    async ({itemId, boardId, columnsValuesString}, {dispatch}) => {
        dispatch(setPageStatus('loading'))
        try {
            const variables = JSON.stringify({
                myBoardId: Number(boardId),
                myItemId: Number(itemId),
                myColumnValues: columnsValuesString
            })
            await monday.api(`mutation ($myBoardId:Int!, $myItemId:Int!, $myColumnValues:JSON!) { change_multiple_column_values(item_id:$myItemId, board_id:$myBoardId, column_values: $myColumnValues, create_labels_if_missing: true) { id } }`, {variables})
        } catch (e) {
            dispatch(setErrorText('Error 500: Internal Server Error. Please contact DonorSearch support.'))
            dispatch(changeLastUpdateStatusColumnOnMondayBoard({
                itemId,
                boardId,
                status: 'Error 500: Internal Server Error. Please contact DonorSearch support.'
            }))
            dispatch(setPageStatus('idle'))
        }
    })


export const getDataFromMondayGraphAPI = createAsyncThunk(
    'AppReducer/getDataFromMondayGraphAPI',
    async ({itemId, boardId, firstName, lastName, middleName, streetAddress, city, state, zipCode, APIKey,isRecursive}, {
        dispatch
    }) => {
        try {
            let query = monday.api(`query { items (ids: [${itemId}]) { name column_values { text id title type }}}`);
            const correctDataFormat = await query
            if (correctDataFormat.data.items[0]) {
                dispatch(setBoardData(correctDataFormat.data.items))
                let columnsIds = []
                for (let i = 0; i < correctDataFormat.data.items[0].column_values.length; i++) {
                    for (let j = 0; j < neededColumnNames.length; j++) {
                        if (correctDataFormat.data.items[0].column_values[i].title === neededColumnNames[j].title) {
                            const object = {
                                [correctDataFormat.data.items[0].column_values[i].title]: correctDataFormat.data.items[0].column_values[i].id,
                                type: correctDataFormat.data.items[0].column_values[i].type
                            }
                            columnsIds.push(object)
                        }
                    }
                }
                dispatch(setIsNeedToCreateColumn(false))


                const neededFieldsIdsNames = neededColumnNames.map(t => t.title)
                const neededToStoreColumnsElems = correctDataFormat.data.items[0].column_values.map(t => neededFieldsIdsNames.indexOf(t.title)!==-1  ? {
                    [t.title]: t.id,
                    type: t.type
                } : null).filter(t => t !== null)
                dispatch(setColumnsIds(neededToStoreColumnsElems))
            }

            if (correctDataFormat.data.items[0].column_values.find(t => t.title === 'Last Update Date')) {
                dispatch(setLastUpdate(correctDataFormat.data.items[0].column_values.find(t => t.title === 'Last Update Date').text))
            }
            if(isRecursive){
                dispatch(getUserDataFromDSApi({itemId, boardId, firstName, lastName, middleName, streetAddress, city, state, zipCode, APIKey,isRecursive:true}))
            }
        } catch (e) {
            console.error(e)
        }
    })


const slice = createSlice({
    name: 'AppReducer',
    initialState: {
        userData: '',
        pageStatus: 'idle',
        lastUpdate: '',
        errorText: false,
        isSuccess: false,
        BoardData: {},
        boardColumns: [],
        neededToCreateColumnsList: [],
        isNeedToCreateColumn: false,
        isReset: false,
        columnsIds: [],
        isRecursionFinished:false
    },
    reducers: {
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
        setIsRecursionFinished: (state, action) => {
            state.isRecursionFinished = action.payload;
        },
        setIsReset: (state, action) => {
            state.isReset = action.payload;
        },
        setColumnsIds: (state, action) => {
            state.columnsIds = action.payload;
        },
        setPageStatus: (state, action) => {
            state.pageStatus = action.payload;
        },
        setErrorText: (state, action) => {
            state.errorText = action.payload;
        },
        setIsSuccess: (state, action) => {
            state.isSuccess = action.payload;
        },
        setBoardData: (state, action) => {
            state.BoardData = action.payload;
        },
        setLastUpdate: (state, action) => {
            state.lastUpdate = action.payload;
        },
        setNeededToCreateColumnsList: (state, action) => {
            state.neededToCreateColumnsList = action.payload;
        },
        setIsNeedToCreateColumn: (state, action) => {
            state.isNeedToCreateColumn = action.payload;
        },
    },
});

export const appReducer = slice.reducer;

export const {
    setPageStatus,
    setErrorText,
    setIsSuccess,
    setBoardData,
    setLastUpdate,
    setColumnsIds,
    setIsNeedToCreateColumn,
    setIsRecursionFinished
} = slice.actions;
