import React from 'react';
import {Toast} from "monday-ui-react-core";
import {useDispatch} from "react-redux";
import {setErrorText} from "../redux/AppReducer";
import './ErrorMsg.css'


export const ErrorMsg = ({isError,isSuccess}) => {

    const dispatch = useDispatch()

    const onCloseToast =() =>{
        dispatch(setErrorText(''))
    }

        return (
        <>
    {isError && <Toast open={isError} onClose={onCloseToast} type={Toast.types.NEGATIVE}
                          className="monday-storybook-toast_wrapper toastContainer">
            {isError}
        </Toast>
    }
    {isSuccess && <Toast open={isSuccess} closeable={false}  type={Toast.types.POSITIVE}
                          className="monday-storybook-toast_wrapper toastContainerSuccess">
        Success. Please reload the window.
        </Toast>
    }
    </>
        )}